import { useEffect } from "react";

const SCROLL_THRESHOLD = 150; // px

export function useScrollClassDirection({ selector }) {
  useEffect(() => {
    let lastScroll = 0;
    const triggerOnScroll = document.querySelectorAll(selector);
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    const addShadowClass = "add-shadow";
    const removeShadowClass = "remove-shadow";

    const showElement = () => {
      triggerOnScroll.forEach((elem) => elem.classList.remove(scrollDown));
      triggerOnScroll.forEach((elem) => elem.classList.add(scrollUp));
    };

    const hideElement = () => {
      triggerOnScroll.forEach((elem) => elem.classList.remove(scrollUp));
      triggerOnScroll.forEach((elem) => elem.classList.add(scrollDown));
    };

    const addShadow = () => {
      triggerOnScroll.forEach((elem) => elem.classList.add(addShadowClass));
      triggerOnScroll.forEach((elem) =>
        elem.classList.remove(removeShadowClass)
      );
    };

    const removeShadow = () => {
      triggerOnScroll.forEach((elem) => elem.classList.remove(addShadowClass));
      triggerOnScroll.forEach((elem) => elem.classList.add(removeShadowClass));
    };

    window.addEventListener(
      "scroll",
      () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll === 0) {
          showElement();
          removeShadow();
          return;
        }
        if (currentScroll <= SCROLL_THRESHOLD) {
          showElement();
          return;
        }

        if (currentScroll > lastScroll) {
          // down
          hideElement();
        } else if (currentScroll < lastScroll) {
          // up
          showElement();
          addShadow();
        }
        lastScroll = currentScroll;
      },
      { passive: true }
    );
  }, [selector]);
}
