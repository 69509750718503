import { styled } from "@theme";

export function Main({ children, variant }) {
  return (
    <StyledMain variant={variant}>
      {children}
    </StyledMain>
  );
}

const StyledMain = styled("main", {
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  backgroundColor: "var(--main-background-color)",

  variants: {
    variant: {
      dark: {
        backgroundColor: "#000",
      },
      light: {
        backgroundColor: "#fff",
      },
      spirograph: {
        backgroundColor: "transparent",
      }
    },
  },
});
