import { styled } from "@theme";

export function OuterContainer({ children }) {
  return (
    <StyledOuterContainer>
      <div />
      {children}
      <div />
    </StyledOuterContainer>
  );
}

const StyledOuterContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "5fr 90fr 5fr",
  gridTemplateRows: "1fr",
  width: "100%",

  "@9": {
    gridTemplateColumns: "12.5% 75% 12.5%",
  },
});
