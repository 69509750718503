import { useRouter } from "next/router";

import { HeaderNavItem } from "../typography/HeaderNavItem";

import { styled } from "@theme";

const TONE_LINKS = [
  {
    activeSLug: "/our-work",
    href: "/our-work",
    title: "Our work",
  },
  {
    activeSLug: "/our-studio",
    href: "/our-studio",
    title: "Our studio",
  },
  {
    activeSLug: "/get-in-touch",
    href: "/get-in-touch",
    title: "Get in touch",
  },
];

const TONE_AND_PARTNERS_LINKS = (variant) => [
  {
    activeSLug: "/get-in-touch",
    href: `#`,
    title: "Get in touch",
    handleScroll: (e) => {
      e.preventDefault();
      const elem = document.getElementById("get-in-touch-section");
      window.scrollTo({
        top: elem?.getBoundingClientRect().top,
        behavior: "smooth",
      });
    }
  },
];

export function SecondaryMenu({ variant }) {
  const router = useRouter();

  const isPartnerVariant = variant.includes("_partner");

  const links = isPartnerVariant
    ? TONE_AND_PARTNERS_LINKS(variant)
    : TONE_LINKS;

  return (
    <SecondaryMenuContainer className="secondary-menu">
      <ul className={isPartnerVariant ? "partner-variant" : ""}>
        {links.map((link) => (
          <HeaderNavItem
            key={link.activeSLug}
            isActive={router?.pathname.includes(link.activeSLug)}
            variant={variant}
            href={link.href}
            handleScroll={link.handleScroll}
          >
            {link.title}
          </HeaderNavItem>
        ))}
      </ul>
    </SecondaryMenuContainer>
  );
}

const SecondaryMenuContainer = styled("nav", {
  display: "flex",
  flex: "1",

  ul: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",


    flex: "1",
    height: "var(--secondary-menu-height)",
    minHeight: "0vw",

    maxWidth: "85vw",
    margin: "0 auto",

    "@media (min-width: 769px)": {
      height: "unset",
      justifyContent: "flex-end",
    },

    "&.partner-variant": {
      justifyContent: "flex-end", 
    }
  },
});
