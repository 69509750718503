import { styled } from "@theme";

export function InnerContainer({ children }) {
  return (
    <InnerContainerLayout>
      <div />
      {children}
      <div />
    </InnerContainerLayout>
  );
}

const InnerContainerLayout = styled("div", {
  display: "grid",
  gridTemplateColumns: "7.5fr 85fr 7.5fr",
  gridTemplateRows: "1fr",
  width: "100%",

  "@media (min-width: 1921px)": {
    gridTemplateColumns: "16.5fr 67fr 16.5fr",
  },
});
