export function ToneLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.31654285714em"
      height="1em"
      viewBox="0 0 186.079 35"
      {...props}
      style={{ overflow: "visible" }}
    >
      <defs>
        <clipPath id="prefix__a">
          <path data-name="Path 47" d="M0 0h186.079v-35H0z" fill="none" />
        </clipPath>
      </defs>
      <g
        data-name="Group 67"
        transform="translate(0 35)"
        clipPath="url(#prefix__a)"
      >
        <g data-name="Group 66">
          <path
            data-name="Path 46"
            d="M68.57-35a17.518 17.518 0 00-17.5 17.5A17.521 17.521 0 0068.57 0a17.521 17.521 0 0017.5-17.5A17.518 17.518 0 0068.57-35m0 32.142A14.659 14.659 0 0153.927-17.5 14.659 14.659 0 0168.57-32.142 14.659 14.659 0 0183.213-17.5 14.659 14.659 0 0168.57-2.858"
            fill="currentColor"
          />
        </g>
      </g>
      <g data-name="Group 68">
        <path
          data-name="Path 48"
          d="M165.367 1.071v32.855h20.712v-5.711h-15v-7.859h15v-5.717h-15V6.784h15V1.071z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 69">
        <path
          data-name="Path 49"
          d="M0 1.029v1.513h15.316v31.387h1.51V2.542h15.316V1.029z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 70">
        <path
          data-name="Path 50"
          d="M109.412 33.926h4.288V9.72l28.567 25V1.071h-4.281v24.2L109.412.278z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
