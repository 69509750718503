import Link from "next/link";

import { OuterContainer } from "@components/layouts/OuterContainer";
import { ToneLogo } from "@components/ToneLogo";
import { ChangeAndCoLogo } from "@components/Partners/ChangeAndCoLogo";
import { ExuberanceLogo } from "@components/Partners/ExuberanceLogo";
import { DigitalNinjasLogo } from "@components/Partners/DigitalNinjasLogo";
import { SecondaryMenu } from "@components/SecondaryMenu";
import { VisuallyHidden } from "@components/VisuallyHidden";

import { capitalize } from "@utils/capitalize";

import { styled } from "@theme";

export function Header({ variant, headerCategoryName, headerCategorySlug }) {
  const headerVariant = variant || "dark";

  const Logo = resolveLogos({
    variant,
    headerCategorySlug,
    headerCategoryName,
    headerVariant,
  });

  return (
    <SemanticHeader>
      <OuterContainer>
        <MenuGroup variant={variant}>
          <MainMenu>
            <Logo.Homepage />

            <Logo.Category />

            <Logo.Partner />
          </MainMenu>
          <SecondaryMenu variant={headerVariant} />
        </MenuGroup>
      </OuterContainer>
    </SemanticHeader>
  );
}

function resolveLogos({
  variant,
  headerCategorySlug,
  headerCategoryName,
  headerVariant,
}) {
  const PARTNERS = {
    tone_only_no_partner: {
      Logo: () => <div>Portfolio</div>,
      logoLink: "portfolio",
      toneLink: "portfolio",
    },
    change_and_co_partner: {
      Logo: ChangeAndCoLogo,
      logoLink: headerCategorySlug,
      toneLink: headerCategorySlug,
    },
    exuberance_partner: {
      Logo: ExuberanceLogo,
      logoLink: headerCategorySlug,
      toneLink: headerCategorySlug,
    },
    digital_ninjas_partner: {
      Logo: DigitalNinjasLogo,
      logoLink: headerCategorySlug,
      toneLink: headerCategorySlug,
    },
  };

  const CurrentPartner = PARTNERS[variant];

  const PartnerLogo = () => {
    if (!CurrentPartner) {
      return null;
    }

    return (
      <VerticalLineSeparatorContainer>
        <VerticalLineSeparator />
        <Link href={`/${CurrentPartner.logoLink}`} passHref>
          <LogoController>
            <CurrentPartner.Logo />
          </LogoController>
        </Link>
      </VerticalLineSeparatorContainer>
    );
  };

  const HomepageLogo = () => {
    return (
      <Link href={`/${CurrentPartner?.toneLink || ""}`} passHref>
        <LogoController>
          <ToneLogo />
          <VisuallyHidden>Go back to homepage</VisuallyHidden>
        </LogoController>
      </Link>
    );
  };

  const CategoryLogo = () => {
    if (!headerCategoryName) {
      return null;
    }

    return (
      <VerticalLineSeparatorContainer>
        <VerticalLineSeparator />
        <Link href={`/${headerCategorySlug}`} passHref>
          <HeaderCategoryName variant={headerVariant}>
            {capitalize(headerCategoryName.replace("-", " "))}
          </HeaderCategoryName>
        </Link>
      </VerticalLineSeparatorContainer>
    );
  };

  return {
    Homepage: HomepageLogo,
    Partner: PartnerLogo,
    Category: CategoryLogo,
  };
}

const SemanticHeader = styled("header", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "inherit",

  minHeight: "0vw",

  height: "var(--header-height)",

  // border: "1px solid black",
});

const MenuGroup = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  variants: {
    variant: {
      spirograph: {
        color: "white",
      },
      light: {
        color: "black",
      },
      dark: {
        color: "white",
      },
      tone_only_no_partner: {
        color: "white",
      },
      change_and_co_partner: {
        color: "white",
      },
      exuberance_partner: {
        color: "white",
      },
      digital_ninjas_partner: {
        color: "white",
      },
    },
  },

  "@media (max-width: 769px)": {
    "> .secondary-menu": {
      display: "none",
    },
  },
});

const MainMenu = styled("nav", {
  display: "flex",
  alignItems: "center",
});

const LogoController = styled("a", {
  display: "flex",
  color: "inherit",

  /* 1.25rem(20px) @ 20rem(320px) increasing to 2.1875rem(35px) @ 160rem(2560px) */
  fontSize: `clamp(
    1.25rem,
    calc(1.25rem + ((1vw - 0.2rem) * 0.6696)),
    2.1875rem
  )`,

  /* Safari resize fix */
  minHeight: "0vw",
});

const VerticalLineSeparatorContainer = styled("div", {
  display: "flex",
  alignItems: "center",
});

const VerticalLineSeparator = styled("div", {
  background:
    "transparent linear-gradient(0deg, #000fa0 0%, #ff3b1e 100%) 0% 0% no-repeat padding-box",

  /* 2.675rem(42.8px) @ 20rem(320px) increasing to 4.6875rem(75px) @ 160rem(2560px) */
  height: `clamp(
    2.675rem,
    calc(2.675rem + ((1vw - 0.2rem) * 1.4375)),
    4.6875rem
  )`,

  /* 0.10625rem(1.7px) @ 20rem(320px) increasing to 0.21875rem(3.5px) @ 160rem(2560px) */
  width: `clamp(
    0.10625rem,
    calc(0.10625rem + ((1vw - 0.2rem) * 0.0804)),
    0.21875rem
  )`,

  /* 1.25rem(20px) @ 20rem(320px) increasing to 4.0625rem(65px) @ 160rem(2560px) */
  margin: `0
    clamp(1.25rem, calc(1.25rem + ((1vw - 0.2rem) * 2.0089)), 4.0625rem)`,

  /* Safari resize fix */
  minHeight: "0vw",
});

const HeaderCategoryName = styled("a", {
  whiteSpace: "nowrap",
  fontWeight: "$300",
  letterSpacing: "var(--character-spacing-0)",

  /* 1.625rem(26px) @ 20rem(320px) increasing to 2.875rem(46px) @ 160rem(2560px) */
  fontSize: `clamp(
    1.625rem,
    calc(1.625rem + ((1vw - 0.2rem) * 0.8929)),
    2.875rem
  )`,

  /* Safari resize fix */
  minHeight: "0vw",

  /* 1.9375rem(31px) @ 20rem(320px) increasing to 3.4375rem(55px) @ 160rem(2560px) */
  lineHeight: `clamp(
    1.9375rem,
    calc(1.9375rem + ((1vw - 0.2rem) * 1.0714)),
    3.4375rem
  )`,
  paddingTop: `clamp(
    0.375rem,
    calc(0.375rem + ((1vw - 0.24375rem) * 0.2765)),
    0.75rem
  )`,

  variants: {
    variant: {
      light: {
        color: "var(--color-000000)",
      },
      dark: {
        color: "var(--color-ffffff)",
      },
    },
  },
});
