export function ChangeAndCoLogo(props) {
  return (
    <svg
    className="small-logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 634.42 69.04"
    width={`${634.42 / 69.04}em`}
    height="1em"
    {...props}
  >
    <path
      d="M492.6 330.81v-14.9h-25.81v-11.4h23.33V289.6h-23.33v-10.32h25.81v-14.9h-42.06v66.43ZM443.54 293H404v13.56h19.84c-2.06 6.82-8 10.68-15.89 10.68-11.22 0-18.22-8.88-18.22-19.93 0-10.68 7.45-19.3 18.13-19.3a16.29 16.29 0 0 1 14.63 8.62h19c-3.77-13.37-18.23-23.52-33.22-23.52-19.3 0-35.55 14.1-35.55 34.2 0 19.48 15.62 34.84 35.28 34.84 22.36 0 35.46-17.51 35.46-35.38Zm-78.1 37.8v-66.42H349.2l.71 42-25.85-42h-14.63v66.43h16.24l-.44-40.58 25.49 40.58Zm-86.28-23.07h-13.91l6.82-22.73.27.09Zm26.13 23.07-26.4-66.43h-13.37l-26.66 66.43h18.22l3.5-9.61h23.07l3.23 9.61Zm-70.53 0v-66.42h-17v24.95h-21.69v-24.95h-17v66.43h17v-26.57h21.73v26.57Zm-62.44-41.2c-1.16-6.82-4.94-12.93-11.22-18.31-6.65-5.66-14-8.17-23.16-8.17-19 0-34.2 15.89-34.2 33.48 0 10.06 3.05 18.5 10.23 25.5a35 35 0 0 0 58.35-16.16h-17.77c-3.5 7.45-9.25 11.22-16 11.22-10.5 0-17.86-9-17.86-19.75 0-10.32 7.18-19.39 17.77-19.39 6.91 0 13.2 4.31 16.07 11.58ZM703.05 263.12c-20.2 0-35 15.44-35 36 0 19.66 17.33 32.94 35.82 32.94 19 0 34.29-15.26 34.29-34.92-.01-19.03-15.72-34.02-35.11-34.02Zm.27 54c-10.86 0-18.31-8.79-18.31-19.75 0-10.5 7.45-19.39 18-19.39s18.13 9.25 18.13 19.66c.04 10.63-7.32 19.52-17.82 19.52ZM662.24 289.6c-1.16-6.82-4.93-12.93-11.22-18.31-6.64-5.66-14-8.17-23.16-8.17-19 0-34.2 15.89-34.2 33.48 0 10.06 3.05 18.5 10.23 25.5a35 35 0 0 0 58.35-16.16h-17.77c-3.5 7.45-9.25 11.22-16 11.22-10.5 0-17.86-9-17.86-19.75 0-10.32 7.18-19.39 17.77-19.39 6.92 0 13.2 4.31 16.07 11.58ZM548 314.29a9.76 9.76 0 0 1-8.08 3.59c-4.4 0-7.63-2.6-7.63-6.55 0-3.5 2.6-6 5.12-7.72Zm-7.81-27.56c-2.78-2.87-3.77-5-3.77-7.18 0-2.42 1.53-4.4 4-4.4 2.6 0 4 1.8 4 4.22 0 2.25-.9 4.22-4.13 7.36m35.73 35.46-9.07-9.34c4-5 5.75-11.13 10.15-21.09h-15.82l-4.84 10.86-7.81-7.9c6.37-4.4 9.15-9.25 9.15-15.26 0-9.52-7.81-16.34-17.41-16.34-10.32 0-18.4 7.45-18.4 17.06 0 5.65 2.33 10.41 6.28 14.45-9 5.84-12.3 11.13-12.3 18.22 0 11.41 10.33 19.3 22.17 19.3 7.1 0 13.11-2.33 19.84-8.07l7.81 8.07Z"
      transform="translate(-103.74 -263.12)"
      fill="currentColor"
    />
  </svg>
  );
}
